import React from "react";

function Home(){
    return (
        <div>
            <h1>Under Construction!</h1>
        </div>
    );
}

export default Home;